import React, { useState } from "react";
import style from "./ImageGallery.module.css";
import Carousel, { Modal, ModalGateway } from "react-images";

import Gallery1 from "../../assets/jpeg/Gallery/1.jpg";
import Gallery2 from "../../assets/jpeg/Gallery/2.jpg";
import Gallery3 from "../../assets/jpeg/Gallery/3.jpg";
import Gallery4 from "../../assets/jpeg/Gallery/4.jpg";
import Gallery5 from "../../assets/jpeg/Gallery/5.jpg";
import Gallery6 from "../../assets/jpeg/Gallery/6.jpg";
import Gallery7 from "../../assets/jpeg/Gallery/7.jpg";
import Gallery8 from "../../assets/jpeg/Gallery/8.jpg";
import Gallery9 from "../../assets/jpeg/Gallery/9.png";
import Gallery10 from "../../assets/jpeg/Gallery/10.png";
import Gallery11 from "../../assets/jpeg/Gallery/11.png";
import Gallery12 from "../../assets/jpeg/Gallery/12.png";
import Gallery13 from "../../assets/jpeg/Gallery/13.png";
import Gallery14 from "../../assets/jpeg/Gallery/14.png";
import Gallery15 from "../../assets/jpeg/Gallery/15.png";
import Gallery16 from "../../assets/jpeg/Gallery/16.jpg";
import Gallery17 from "../../assets/jpeg/Gallery/17.png";
import Gallery18 from "../../assets/jpeg/Gallery/18.jpg";
import Gallery19 from "../../assets/jpeg/Gallery/19.jpg";
import Gallery20 from "../../assets/jpeg/Gallery/20.jpg";
import Gallery21 from "../../assets/jpeg/Gallery/21.jpg";
import Gallery22 from "../../assets/jpeg/Gallery/22.jpg";
import Gallery23 from "../../assets/jpeg/Gallery/23.jpg";
import Gallery24 from "../../assets/jpeg/Gallery/24.jpg";
import Gallery25 from "../../assets/jpeg/Gallery/25.jpg";
import Gallery26 from "../../assets/jpeg/Gallery/26.jpg";
import Gallery27 from "../../assets/jpeg/Gallery/27.jpg";
import Gallery28 from "../../assets/jpeg/Gallery/28.jpg";
import Gallery29 from "../../assets/jpeg/Gallery/29.jpg";
import Gallery30 from "../../assets/jpeg/Gallery/30.jpg";
import Gallery31 from "../../assets/jpeg/Gallery/31.jpg";
import Gallery32 from "../../assets/jpeg/Gallery/32.jpg";
import Gallery33 from "../../assets/jpeg/Gallery/33.jpg";
import Gallery34 from "../../assets/jpeg/Gallery/34.jpg";
import Gallery35 from "../../assets/jpeg/Gallery/35.jpg";
import Gallery36 from "../../assets/jpeg/Gallery/36.jpg";
import Gallery37 from "../../assets/jpeg/Gallery/37.jpg";
import Gallery38 from "../../assets/jpeg/Gallery/38.jpg";
import Gallery39 from "../../assets/jpeg/Gallery/39.jpg";
import Gallery40 from "../../assets/jpeg/Gallery/40.jpg";
import Gallery41 from "../../assets/jpeg/Gallery/41.jpg";
import Gallery42 from "../../assets/jpeg/Gallery/42.jpg";
import Gallery43 from "../../assets/jpeg/Gallery/43.jpg";
import Gallery44 from "../../assets/jpeg/Gallery/44.jpg";
import Gallery45 from "../../assets/jpeg/Gallery/45.jpg";
import Gallery46 from "../../assets/jpeg/Gallery/46.jpg";

const ImageGallery = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const images = [
        { src: Gallery1, caption: "Image1" },
        { src: Gallery2, caption: "Image2" },
        { src: Gallery3, caption: "Image3" },
        { src: Gallery4, caption: "Image4" },
        { src: Gallery5, caption: "Image5" },
        { src: Gallery6, caption: "Image6" },
        { src: Gallery7, caption: "Image7" },
        { src: Gallery8, caption: "Image8" },
        { src: Gallery9, caption: "Image9" },
        { src: Gallery10, caption: "Image10" },
        { src: Gallery11, caption: "Image11" },
        { src: Gallery12, caption: "Image12" },
        { src: Gallery13, caption: "Image13" },
        { src: Gallery14, caption: "Image14" },
        { src: Gallery15, caption: "Image15" },
        { src: Gallery16, caption: "Image16" },
        { src: Gallery17, caption: "Image17" },
        { src: Gallery18, caption: "Image18" },
        { src: Gallery19, caption: "Image19" },
        { src: Gallery20, caption: "Image20" },
        { src: Gallery21, caption: "Image21" },
        { src: Gallery22, caption: "Image22" },
        { src: Gallery23, caption: "Image23" },
        { src: Gallery24, caption: "Image24" },
        { src: Gallery25, caption: "Image25" },
        { src: Gallery26, caption: "Image26" },
        { src: Gallery27, caption: "Image27" },
        { src: Gallery28, caption: "Image28" },
        { src: Gallery29, caption: "Image29" },
        { src: Gallery30, caption: "Image30" },
        { src: Gallery31, caption: "Image31" },
        { src: Gallery32, caption: "Image32" },
        { src: Gallery33, caption: "Image33" },
        { src: Gallery34, caption: "Image34" },
        { src: Gallery35, caption: "Image35" },
        { src: Gallery36, caption: "Image36" },
        { src: Gallery37, caption: "Image37" },
        { src: Gallery38, caption: "Image38" },
        { src: Gallery39, caption: "Image39" },
        { src: Gallery40, caption: "Image40" },
        { src: Gallery41, caption: "Image41" },
        { src: Gallery42, caption: "Image42" },
        { src: Gallery43, caption: "Image43" },
        { src: Gallery44, caption: "Image44" },
        { src: Gallery45, caption: "Image45" },
        { src: Gallery46, caption: "Image46" },
    ];

    // Function to open the lightbox
    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    // Function to close the lightbox
    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };
    return (
        <>
            <div className={style.containerSection}>
                <div className={style.innerImage}>
                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={image.src}
                            alt={image.caption}
                            onClick={() => openLightbox(index)}
                            className={style.galleryView}
                        />
                    ))}
                </div>
                <ModalGateway>
                    {viewerIsOpen && (
                        <Modal onClose={closeLightbox}>
                            <Carousel
                                currentIndex={currentImage}
                                views={images}
                            />
                        </Modal>
                    )}
                </ModalGateway>
            </div>
        </>
    );
};

export default ImageGallery;
