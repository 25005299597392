import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import style from "./Header.module.css";

import FbImg from "../../assets/svg/fb.svg";
import Instra from "../../assets/svg/in.svg";
import CloseBtn from "../../assets/svg/close.svg";
import toggle from "../../assets/png/nav.png";

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const handleHome = (e) => {
        e.preventDefault();
        navigate("/");
    };
    const handleGuest = (e) => {
        e.preventDefault();
        navigate("/guest");
    };
    const handleGallery = (e) => {
        e.preventDefault();
        navigate("/gallery");
    };
    const handleContact = (e) => {
        e.preventDefault();
        navigate("/contactUs");
    };
    return (
        <div className={style.headerSection}>
            <ul className={style.navBar}>
                <li onClick={handleHome}>Home</li>
                <li onClick={handleGuest}>Guest Cabins</li>
                <li onClick={handleGallery}>Gallery</li>
                <li onClick={handleContact}>Contact Us</li>
            </ul>
            <div className={style.socialIcons}>
                <ul>
                    <li>
                        <Link
                            to="https://www.facebook.com/crossbridgecreek/"
                            target="_blank"
                        >
                            <img
                                src={FbImg}
                                alt="FbImg"
                                className={style.svgIcons}
                            />
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="https://www.instagram.com/crossbridge_creek/"
                            target="_blank"
                        >
                            <img
                                src={Instra}
                                alt="Instra"
                                className={style.svgIcons}
                            />
                        </Link>
                    </li>
                    <li>
                        <div onClick={() => setIsOpen(true)}>
                            <img
                                src={toggle}
                                alt="Toggle"
                                className={style.openIcons}
                            />
                        </div>
                    </li>
                </ul>
            </div>
            {isOpen && (
                <div className={style.modalOpen}>
                    <div className={style.innerModal}>
                        {/* <h2 className={style.modalTitle}>Modal Open</h2> */}
                        <div
                            className={style.closeBtnSize}
                            onClick={() => setIsOpen(false)}
                        >
                            <img
                                className={style.closeBtn}
                                alt="Close Btn"
                                src={CloseBtn}
                            />
                        </div>
                        <ul className={style.mobileNavBar}>
                            <li onClick={handleHome}>Home</li>
                            <li onClick={handleGuest}>Guest Cabins</li>
                            <li onClick={handleGallery}>Gallery</li>
                            <li onClick={handleContact}>Contact Us</li>
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Header;
