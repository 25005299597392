import React, { useEffect } from "react";
import BannerBrown from "../../features/BannerBrown/BannerBrown";
import Header from "../../features/Header/Header";
import Footer from "../../features/Footer/Footer";
import ImageGallery from "../../features/ImageGallery/ImageGallery";

const Gallery = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);
    return (
        <div>
            <Header />
            <BannerBrown Title={"GALLERY"} />
            <ImageGallery />
            <Footer />
        </div>
    );
};

export default Gallery;
