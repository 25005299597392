import React from "react";
import style from "./VenueSection.module.css";
import showImg from '../../assets/jpeg/venew.jpg';
// import EastIcon from '@mui/icons-material/East';
import MuiButton from "../../ui/button/MuiButton";
import { useNavigate } from "react-router-dom";

const VenueSection = () => {
    const navigate = useNavigate();
    const handleGalleryPage = (e) => {
        e.preventDefault();
        navigate('/gallery');
    }
    return (
        <div className={style.containerSection}>
            <div className={style.VenueSection}>
                <div className={style.imageViews}>
                    <img src={showImg} alt='Venue img' className={style.venueImg} />
                </div>
                <div className={style.contentViews}>
                    <h3>MAKE MEMORIES IN A SERENE ATMOSPHERE!</h3>
                    <h2>WORRY LESS ABOUT THE DETAILS AND FOCUS MORE ON THE FUN!</h2>
                    <p>Enjoy the tranquil surroundings of nature with those you love most.  Crossbridge Creek is a lavish 17 acre property with two luxury guest houses, winding creek and bridge, walking trails, sparkling swimming pool and top of the line tennis court. We would love to host your family for a vacation that you will never forget!</p>
                    <p>We also can design a customized party package for weddings/ baby showers/ family get togethers.</p>

                    <div className={style.buttonField}>
                        <MuiButton 
                            CustomStyle={style.btnClass}
                            LabelName={'SEE THE VENUE GALLERY'}
                            onClick={handleGalleryPage}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VenueSection;
