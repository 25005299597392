import React from "react";
import style from "./WoodLandRoom.module.css";
import CarouselImage from "../../ui/carousel/CarouselImage";

import WoodLand1 from "../../assets/jpeg/WoodLand/1.jpg";
import WoodLand2 from "../../assets/jpeg/WoodLand/2.jpg";
import WoodLand3 from "../../assets/jpeg/WoodLand/3.jpg";
import WoodLand4 from "../../assets/jpeg/WoodLand/4.jpg";
import WoodLand5 from "../../assets/jpeg/WoodLand/5.jpg";
import WoodLand6 from "../../assets/jpeg/WoodLand/6.jpg";
import WoodLand7 from "../../assets/jpeg/WoodLand/7.jpg";
import WoodLand8 from "../../assets/jpeg/WoodLand/8.jpg";
import WoodLand9 from "../../assets/jpeg/WoodLand/9.jpg";
import WoodLand10 from "../../assets/jpeg/WoodLand/10.jpg";
import WoodLand11 from "../../assets/jpeg/WoodLand/11.jpg";
import WoodLand12 from "../../assets/jpeg/WoodLand/12.jpg";
import WoodLand13 from "../../assets/jpeg/WoodLand/13.jpg";
import WoodLand14 from "../../assets/jpeg/WoodLand/14.jpg";
import WoodLand15 from "../../assets/jpeg/WoodLand/15.jpg";
import WoodLand16 from "../../assets/jpeg/WoodLand/16.jpg";
import WoodLand17 from "../../assets/jpeg/WoodLand/17.jpg";
import WoodLand18 from "../../assets/jpeg/WoodLand/18.jpg";
import WoodLand19 from "../../assets/jpeg/WoodLand/19.jpg";
import WoodLand20  from "../../assets/jpeg/WoodLand/20.jpg";
import WoodLand21  from "../../assets/jpeg/WoodLand/21.jpg";
import WoodLand22  from "../../assets/jpeg/WoodLand/22.jpg";
import WoodLand23  from "../../assets/jpeg/WoodLand/23.jpg";
import WoodLand24  from "../../assets/jpeg/WoodLand/24.jpg";
import { Link } from "react-router-dom";

const WoodLandRoom = () => {
    const woodLandSource = [
        { image: WoodLand1 },
        { image: WoodLand2 },
        { image: WoodLand3 },
        { image: WoodLand4 },
        { image: WoodLand5 },
        { image: WoodLand6 },
        { image: WoodLand7 },
        { image: WoodLand8 },
        { image: WoodLand9 },
        { image: WoodLand10 },
        { image: WoodLand11 },
        { image: WoodLand12 },
        { image: WoodLand13 },
        { image: WoodLand14 },
        { image: WoodLand15 },
        { image: WoodLand16 },
        { image: WoodLand17 },
        { image: WoodLand18 },
        { image: WoodLand19 },
        { image: WoodLand20 },
        { image: WoodLand21 },
        { image: WoodLand22 },
        { image: WoodLand23 },
        { image: WoodLand24 },
    ];

    return (
        <>
            <div className={style.sectionContainer}>
                <div className={style.innerSection}>
                    <h2>Woodland Retreat with Pool & Tennis court access!</h2>
                    <h4>(6 guests 3 bedrooms 4 beds 1 bathroom)</h4>
                </div>
                <div className={style.CaroselSection}>
                    <CarouselImage
                        Data={woodLandSource}
                        cols={3}
                        rows={1}
                        gap={0}
                        style={style.dataStyle}
                    />
                </div>
                <div className={style.cabinDetails}>
                    <p>
                        Cozy home nestled in the trees! Your family will enjoy a
                        peaceful getaway in this 3 bedroom home surrounded by
                        nature, yet enjoying the convenience of Edmond
                        restaurants and shopping.
                    </p>
                    <p>
                        Home is on the corner of a 17 acre estate with a pond,
                        creek, tennis court and pool. Guests can enjoy access to
                        the grounds and swimming (May to Oct) & tennis by
                        reservation at no additional charge.
                    </p>
                    <p>
                        Ask hosts if you want to arrange a family reunion- two
                        additional guest houses nearby!
                    </p>
                    <p>NO SMOKING/ NO PETS</p>
                    <p>
                    Check out our links to <Link to={'https://www.airbnb.com/h/woodlandretreatedmond'} target="_blank" style={{color: '#2196F3',fontWeight: 600,letterSpacing: '.5px'}} > Airbnb </Link> &  <Link to={'https://www.vrbo.com/2983681?unitId=3555721'} target="_blank" style={{color: '#2196F3',fontWeight: 600,letterSpacing: '.5px'}} > VRBO </Link> for availability
                    </p>
                </div>
                {/* <div className={style.bookingForm}>
                    <h2>INQUIRE ABOUT BOOKING THIS WOODLAND RETREAT!</h2>

                    <div className={style.formPage}>
                        <div className={style.dateField}>
                            <div className={style.formControl}>
                                <label>Date to Check-in *</label>
                                <input
                                    className={style.inputDate}
                                    type="date"
                                    placeholder="DD/MM/YYYY"
                                ></input>
                                <div
                                    id="bf2-2-error"
                                    className={style.errorTxt}
                                >
                                    <p>This field is required</p>
                                </div>
                            </div>
                            <div className={style.formControl}>
                                <label>Date to Check-out *</label>
                                <input
                                    className={style.inputDate}
                                    type="date"
                                    placeholder="DD/MM/YYYY"
                                ></input>
                                <div
                                    id="bf2-2-error"
                                    className={style.errorTxt}
                                >
                                    <p>This field is required</p>
                                </div>
                            </div>
                        </div>
                        <div className={style.inputField}>
                            <label>Name *</label>
                            <input
                                type="text"
                                className={style.inputForm}
                                placeholder="Your Name"
                            ></input>
                            <div id="bf2-2-error" className={style.errorTxt}>
                                <p>This field is required</p>
                            </div>
                        </div>
                        <div className={style.inputField}>
                            <label>Email *</label>
                            <input
                                type="text"
                                className={style.inputForm}
                                placeholder="Your Email"
                            ></input>
                            <div id="bf2-2-error" className={style.errorTxt}>
                                <p>This field is required</p>
                            </div>
                        </div>
                        <div className={style.inputField}>
                            <label>Phone Number</label>
                            <input
                                type="text"
                                className={style.inputForm}
                                placeholder="Your Phone Number"
                            ></input>
                            <div id="bf2-2-error" className={style.errorTxt}>
                                <p>This field is required</p>
                            </div>
                        </div>
                        <div className={style.inputField}>
                            <label>Number of Guests *</label>
                            <input
                                type="text"
                                className={style.inputForm}
                                placeholder="Number of Guests"
                            ></input>
                            <div id="bf2-2-error" className={style.errorTxt}>
                                <p>This field is required</p>
                            </div>
                        </div>
                        <div className={style.inputField}>
                            <label>Any Details or questions </label>
                            <textarea
                                type="text"
                                className={style.textareaForm}
                                placeholder="Type Your Question here"
                            ></textarea>
                            <div id="bf2-2-error" className={style.errorTxt}>
                                <p>This field is required</p>
                            </div>
                        </div>
                        <div className={style.buttonField}>
                            <MuiButton
                                CustomStyle={style.buttonSubmit}
                                LabelName={"Submit"}
                                iconStyle={{ display: "none" }}
                            />
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default WoodLandRoom;
