import React from "react";
import style from "./PrivateCabin.module.css";
import CarouselImage from "../../ui/carousel/CarouselImage";

import cabin1 from "../../assets/jpeg/Cabin/1.jpg";
import cabin2 from "../../assets/jpeg/Cabin/2.jpg";
import cabin3 from "../../assets/jpeg/Cabin/3.jpg";
import cabin4 from "../../assets/jpeg/Cabin/4.jpg";
import cabin5 from "../../assets/jpeg/Cabin/5.jpg";
import cabin6 from "../../assets/jpeg/Cabin/6.jpg";
import cabin7 from "../../assets/jpeg/Cabin/7.jpg";
import cabin8 from "../../assets/jpeg/Cabin/8.jpg";
import cabin9 from "../../assets/jpeg/Cabin/9.jpg";
import cabin10 from "../../assets/jpeg/Cabin/10.jpg";
import cabin11 from "../../assets/jpeg/Cabin/11.jpg";
import cabin12 from "../../assets/jpeg/Cabin/12.jpg";
import cabin13 from "../../assets/jpeg/Cabin/13.jpg";
import cabin14 from "../../assets/jpeg/Cabin/14.jpg";
import cabin15 from "../../assets/jpeg/Cabin/15.jpg";
import cabin16 from "../../assets/jpeg/Cabin/16.jpg";
import cabin17 from "../../assets/jpeg/Cabin/17.jpg";
import cabin18 from "../../assets/jpeg/Cabin/18.jpg";
import cabin19 from "../../assets/jpeg/Cabin/19.jpg";
import cabin20 from "../../assets/jpeg/Cabin/20.jpg";
import cabin21 from "../../assets/jpeg/Cabin/21.jpg";
import cabin22 from "../../assets/jpeg/Cabin/22.jpg";
import cabin23 from "../../assets/jpeg/Cabin/23.jpg";
import cabin24 from "../../assets/jpeg/Cabin/24.jpg";
import cabin25 from "../../assets/jpeg/Cabin/25.jpg";
import cabin26 from "../../assets/jpeg/Cabin/26.jpg";
import cabin27 from "../../assets/jpeg/Cabin/27.jpg";
import cabin28 from "../../assets/jpeg/Cabin/28.jpg";
import cabin29 from "../../assets/jpeg/Cabin/29.jpg";
import cabin30 from "../../assets/jpeg/Cabin/30.jpg";
import cabin31 from "../../assets/jpeg/Cabin/31.jpg";
import { Link } from "react-router-dom";

const PrivateCabin = () => {
    const dataSource = [
        { image: cabin1 },
        { image: cabin2 },
        { image: cabin3 },
        { image: cabin4 },
        { image: cabin5 },
        { image: cabin6 },
        { image: cabin7 },
        { image: cabin8 },
        { image: cabin9 },
        { image: cabin10 },
        { image: cabin11 },
        { image: cabin12 },
        { image: cabin13 },
        { image: cabin14 },
        { image: cabin15 },
        { image: cabin16 },
        { image: cabin17 },
        { image: cabin18 },
        { image: cabin19 },
        { image: cabin20 },
        { image: cabin21 },
        { image: cabin22 },
        { image: cabin22 },
        { image: cabin23 },
        { image: cabin24 },
        { image: cabin25 },
        { image: cabin26 },
        { image: cabin27 },
        { image: cabin28 },
        { image: cabin29 },
        { image: cabin30 },
        { image: cabin31 },
    ];

    return (
        <>
            <div className={style.sectionContainer}>
                <div className={style.innerSection}>
                    <h2>Private Cabin on Acreage!</h2>
                    <h4>(1 King bed/ 1 Queen Sleeper Sofa/1 Bath/ Full Kitchen and Living Rooms)</h4>
                </div>
                <div className={style.CaroselSection}>
                    <CarouselImage
                        Data={dataSource}
                        cols={3}
                        rows={1}
                        gap={0}
                        style={style.dataStyle}
                    />
                </div>
                <div className={style.cabinDetails}>
                    <p>
                        You will love the beautiful setting of this private
                        cabin located on the corner of our 17 acre property! You
                        are welcome to enjoy the pond and trails on the property
                        for walking, biking. We have a tennis court available
                        for use by reservation, at no additional charge.
                    </p>
                    <ul className={style.cabinList}>
                        <li>Covered parking with a carport.</li>
                        <li>
                            Kitchen is equipped for food preparation. Wi-Fi and
                            Smart TV.
                        </li>
                    </ul>
                    <h4>SLEEPING ARRANGEMENTS:</h4>
                    <ul className={style.cabinList}>
                        <li>1 Queen size bed</li>
                        <li>1 Queen size inflatable mattress</li>
                    </ul>
                    <p>
                        Pool is open seasonally: from May to September- located
                        behind the main house a short walk from the cabin. The
                        pool is a shared amenity with the host and another guest
                        cabin. We can coordinate private pool time for your
                        family by request.
                    </p>
                    <h4>POLICIES:</h4>
                    <ul className={style.cabinList}>
                        <li>
                            No smoking in the house or anywhere on the property.
                        </li>
                        <li>No pets.</li>
                        <li>
                            No parties or events without special permission and
                            additional fees.
                        </li>
                        <li>Max 4 guests in house</li>
                    </ul>
                    <h4>
                        Hosts live on site and are available for assistance
                        during your stay.
                    </h4>
                    <p>
                    Check out our links to <Link to={'https://www.airbnb.com/h/privatecabintenniscourt'} target="_blank" style={{color: '#2196F3',fontWeight: 600,letterSpacing: '.5px'}} > Airbnb </Link> &  <Link to={'https://www.vrbo.com/2746520?unitId=3318393'} target="_blank" style={{color: '#2196F3',fontWeight: 600,letterSpacing: '.5px'}} > VRBO </Link> for availability
                    </p>
                </div>
                {/* <div className={style.bookingForm}>
                    <h2>INQUIRE ABOUT BOOKING THIS CABIN!</h2>

                    <div className={style.formPage}>
                        <div className={style.dateField}>
                            <div className={style.formControl}>
                                <label>Date to Check-in *</label>
                                <input className={style.inputDate} type="date" placeholder="DD/MM/YYYY" ></input>
                                <div id="bf2-2-error" className={style.errorTxt}><p>This field is required</p></div>
                            </div>
                            <div className={style.formControl}>
                                <label>Date to Check-out *</label>
                                <input className={style.inputDate} type="date" placeholder="DD/MM/YYYY" ></input>
                                <div id="bf2-2-error"  className={style.errorTxt}><p>This field is required</p></div>
                            </div>
                        </div>
                        <div className={style.inputField}>
                            <label>Name *</label>
                            <input type="text" className={style.inputForm} placeholder="Your Name"></input>
                            <div id="bf2-2-error"  className={style.errorTxt}><p>This field is required</p></div>
                        </div>
                        <div className={style.inputField}>
                            <label>Email *</label>
                            <input type="text" className={style.inputForm} placeholder="Your Email"></input>
                            <div id="bf2-2-error"  className={style.errorTxt}><p>This field is required</p></div>
                        </div>
                        <div className={style.inputField}>
                            <label>Phone Number</label>
                            <input type="text" className={style.inputForm} placeholder="Your Phone Number"></input>
                            <div id="bf2-2-error"  className={style.errorTxt}><p>This field is required</p></div>
                        </div>
                        <div className={style.inputField}>
                            <label>Number of Guests *</label>
                            <input type="text" className={style.inputForm} placeholder="Number of Guests"></input>
                            <div id="bf2-2-error"  className={style.errorTxt}><p>This field is required</p></div>
                        </div>
                        <div className={style.inputField}>
                            <label>Any Details or questions </label>
                            <textarea type="text" className={style.textareaForm} placeholder="Type Your Question here"></textarea>
                            <div id="bf2-2-error"  className={style.errorTxt}><p>This field is required</p></div>
                        </div>
                        <div className={style.buttonField}>
                            <MuiButton 
                                CustomStyle={style.buttonSubmit}
                                LabelName={'Submit'}
                                iconStyle={{display:'none'}}
                            />
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default PrivateCabin;
