import React, { useEffect } from 'react';
import Header from '../../features/Header/Header';
import BannerBrown from '../../features/BannerBrown/BannerBrown';
import Footer from '../../features/Footer/Footer';
import ContactImage from '../../features/ContactImage/ContactImage';
import ContactMap from '../../features/ContactMap/ContactMap';

const Contact = () => {
    useEffect(() => {
        setTimeout(() => {
           window.scrollTo(0, 0); // Scroll to the top of the page
        }, 200);
   }, []);
    return (
        <div>
            <Header />
            <BannerBrown Title={"CONTACT US"} />
            <ContactImage />
            <ContactMap />
            <Footer />
        </div>
    );
};

export default Contact;