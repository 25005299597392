import React from 'react';
import style from './BannerBrown.module.css';
const BannerBrown = ({Title}) => {
    return (
        <div className={style.BannerCover}>
            <div className={style.pageContainer}>
                <h2>{Title}</h2>
            </div>
        </div>
    );
};

export default BannerBrown;