import React from "react";
import Carousel from "better-react-carousel";

const CarouselImage = ({ Data, cols, rows, gap, style }) => {
    return (
        <div>
            <Carousel cols={cols} rows={rows} gap={gap} showDots loop>
                {Data.map((data, index) => {
                    return (
                        <Carousel.Item key={index}>
                            <img width="100%" className={style} src={data.image} alt="Carousel Img" />
                        </Carousel.Item>
                    )
                })}
            </Carousel>
        </div>
    );
};

export default CarouselImage;
