import { Route, BrowserRouter, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Guest from "./pages/Guest/Guest";
import Gallery from "./pages/Gallery/Gallery";
import Contact from "./pages/Contact/Contact";


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/guest" element={<Guest />} />
          <Route path="/gallery" element={<Gallery />}  />
          <Route path="/contactUs" element={<Contact />} />
        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
