import React, { useState } from "react";
import style from "./ContactImage.module.css";
import tree1 from "../../assets/tree1.jpg";
import tree2 from "../../assets/tree2.png";
import MuiButton from "../../ui/button/MuiButton";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";

const ContactImage = () => {
    const [selectedValue, setSelectedValue] = useState("");
    const [selectedData, setSelecteData] = useState("");

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };
    const handleDataChange = (event) => {
        setSelecteData(event.target.value);
    };
    return (
        <div className={style.containerView}>
            <div className={style.Memories}>
                <h2>Make Memories in a serene atmosphere!</h2>
                <div className={style.imageTrees}>
                    <img src={tree2} alt="imageTrees" className={style.trees} />
                    <img src={tree1} alt="imageTrees" className={style.trees} />
                </div>
            </div>

            <div className={style.packages}>
                <h2>Parties & Events Packages</h2>
                <p>Host a birthday party, baby shower, or corporate event!</p>
                <ul className={style.packagesPrice}>
                    <li>
                        $150/hour for parties with up to 30 guests ⁃ including
                        tables and chairs
                    </li>
                    <li>
                        $175/hour for parties with up to 50 guests ⁃ including
                        tables and chairs{" "}
                    </li>
                    <li>
                        Option to add on an overnight stay in guest cabin ⁃
                        starting at $195/night including cleaning fee
                    </li>
                </ul>
                <p>Wedding Packages</p>
                <ul className={style.packagesPrice}>
                    <li> Prices dependent on season </li>
                    <li>Includes tables, chairs, outdoor lighting</li>
                    <li>Access to three guest cabins and 17 acre property</li>
                    <li>Multiple options for ceremony and reception site</li>
                </ul>
            </div>

            <div className={style.formSection}>
                <h3>TELL US ALL ABOUT YOUR PLANS</h3>
                {/* <div className={style.divider}></div> */}

                <iframe 
                    src="https://pxzqjoywqle.typeform.com/to/CBBFJjgi" 
                    height="600" 
                    width="100%"
                ></iframe>

                {/* <div className={style.formPage}>
                    <div className={style.dateField}>
                        <div className={style.formControl}>
                            <label>First Name</label>
                            <input
                                type="text"
                                className={style.inputForm}
                                placeholder="Enter Your First Name"
                            ></input>
                            <div id="bf2-2-error" className={style.errorTxt}>
                                <p>This field is required</p>
                            </div>
                        </div>
                        <div className={style.formControl}>
                            <label>Last Name</label>
                            <input
                                type="text"
                                className={style.inputForm}
                                placeholder="Enter Your Last Name"
                            ></input>
                            <div id="bf2-2-error" className={style.errorTxt}>
                                <p>This field is required</p>
                            </div>
                        </div>
                    </div>
                    <div className={style.inputField}>
                        <label>Email </label>
                        <input
                            type="text"
                            className={style.inputForm}
                            placeholder="example@gmail.com"
                        ></input>
                        <div id="bf2-2-error" className={style.errorTxt}>
                            <p>This field is required</p>
                        </div>
                    </div>
                    <div className={style.inputField}>
                        <label>Phone Number</label>
                        <input
                            type="text"
                            className={style.inputForm}
                            placeholder="Your Phone Number"
                        ></input>
                        <div id="bf2-2-error" className={style.errorTxt}>
                            <p>This field is required</p>
                        </div>
                    </div>
                    <div className={style.inputField}>
                        <label>Event Type</label>
                        <TextField
                            select
                            label=""
                            value={selectedValue}
                            onChange={handleChange}
                            className={style.selectForm}
                            placeholder="Select"
                            SelectProps={{
                                sx: { p: 0 },
                                defaultValue: "select", // Set the default value for the select input
                            }}
                        >
                            <MenuItem value="select" disabled>
                                Select an option
                            </MenuItem>
                            <MenuItem value="Wedding">Wedding</MenuItem>
                            <MenuItem value="Baby Shower">Baby Shower</MenuItem>
                            <MenuItem value="Birthday Party">
                                Birthday Party
                            </MenuItem>
                            <MenuItem value="Style shoot">Style shoot</MenuItem>
                            <MenuItem value="Get Together">
                                Get Together
                            </MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                        </TextField>
                        <div id="bf2-2-error" className={style.errorTxt}>
                            <p>This field is required</p>
                        </div>
                    </div>
                    <div className={style.inputField}>
                        <label>Date of Event</label>
                        <input
                            className={style.inputDate}
                            type="date"
                            placeholder="DD/MM/YYYY"
                        ></input>
                        <div id="bf2-2-error" className={style.errorTxt}>
                            <p>This field is required</p>
                        </div>
                    </div>
                    <div className={style.inputField}>
                        <label>
                            Tell us a Little More about your requirements
                        </label>
                        <textarea
                            type="text"
                            className={style.textareaForm}
                            placeholder="Type here Text ..."
                        ></textarea>
                        <div id="bf2-2-error" className={style.errorTxt}>
                            <p>This field is required</p>
                        </div>
                    </div>

                    <div className={style.inputField}>
                        <label>Estimated Guest Count</label>
                        <TextField
                            select
                            label=""
                            value={selectedData}
                            onChange={handleDataChange}
                            className={style.selectForm}
                            placeholder="Select"
                        >
                            <MenuItem value="select" disabled>
                                Select an option
                            </MenuItem>
                            <MenuItem value="Less than 50">
                                Less than 50
                            </MenuItem>
                            <MenuItem value="Less than 100">
                                Less than 100
                            </MenuItem>
                            <MenuItem value="Less than 150">
                                Less than 150
                            </MenuItem>
                            <MenuItem value="Less than 200">
                                Less than 200
                            </MenuItem>
                        </TextField>
                        <div id="bf2-2-error" className={style.errorTxt}>
                            <p>This field is required</p>
                        </div>
                    </div>
                    <div className={style.buttonField}>
                        <MuiButton
                            CustomStyle={style.buttonSubmit}
                            LabelName={"Submit"}
                            iconStyle={{ display: "none" }}
                        />
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default ContactImage;
