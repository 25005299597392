import React from "react";
import style from "./PoolsideCabana.module.css";
import CarouselImage from "../../ui/carousel/CarouselImage";

import poolside1 from "../../assets/jpeg/Poolside/1.jpg";
import poolside2 from "../../assets/jpeg/Poolside/2.jpg";
import poolside3 from "../../assets/jpeg/Poolside/3.jpg";
import poolside4 from "../../assets/jpeg/Poolside/4.jpg";
import poolside5 from "../../assets/jpeg/Poolside/5.jpg";
import poolside6 from "../../assets/jpeg/Poolside/6.jpg";
import poolside7 from "../../assets/jpeg/Poolside/7.jpg";
import poolside8 from "../../assets/jpeg/Poolside/8.jpg";
import poolside9 from "../../assets/jpeg/Poolside/9.jpg";
import poolside10 from "../../assets/jpeg/Poolside/10.jpg";
import poolside11 from "../../assets/jpeg/Poolside/11.jpg";
import poolside12 from "../../assets/jpeg/Poolside/12.jpg";
import poolside13 from "../../assets/jpeg/Poolside/13.jpg";
import poolside14 from "../../assets/jpeg/Poolside/14.jpg";
import poolside15 from "../../assets/jpeg/Poolside/15.jpg";
import poolside16 from "../../assets/jpeg/Poolside/16.jpg";
import poolside17 from "../../assets/jpeg/Poolside/17.jpg";
import poolside18 from "../../assets/jpeg/Poolside/18.jpg";
import poolside19 from "../../assets/jpeg/Poolside/19.jpg";
import poolside20 from "../../assets/jpeg/Poolside/20.jpg";
import poolside21 from "../../assets/jpeg/Poolside/21.jpg";
import poolside22 from "../../assets/jpeg/Poolside/22.jpg";
import { Link } from "react-router-dom";

const PoolsideCabana = () => {
    const poolsideSource = [
        { image: poolside1 },
        { image: poolside2 },
        { image: poolside3 },
        { image: poolside4 },
        { image: poolside5 },
        { image: poolside6 },
        { image: poolside7 },
        { image: poolside8 },
        { image: poolside9 },
        { image: poolside10 },
        { image: poolside11 },
        { image: poolside12 },
        { image: poolside13 },
        { image: poolside14 },
        { image: poolside15 },
        { image: poolside16 },
        { image: poolside17 },
        { image: poolside18 },
        { image: poolside19 },
        { image: poolside20 },
        { image: poolside21 },
        { image: poolside22 },
    ];

    return (
        <>
            <div className={style.sectionContainer}>
                <div className={style.innerSection}>
                    <h2>Poolside Cabana</h2>
                    <h4>
                        (1 Queen Bed/1 Queen Air Mattress/ 1 Bath/ Full Kitchen
                        and Living Room)
                    </h4>
                </div>
                <div className={style.CaroselSection}>
                    <CarouselImage
                        Data={poolsideSource}
                        cols={3}
                        rows={1}
                        gap={0}
                        style={style.dataStyle}
                    />
                </div>
                <div className={style.cabinDetails}>
                    <p>
                        This is the perfect spot to relax and enjoy nature in
                        the middle of Edmond Oklahoma! Enjoy our modern and
                        comfortable guesthouse on a beautiful 17 acre unique
                        property with pool and tennis court access. Living room
                        and porch faces beautiful greenspace, so tranquil!
                    </p>
                    <p>Fully equipped kitchen, Wi-Fi and television</p>
                    <h4>SLEEPING ARRANGEMENTS:</h4>
                    <ul className={style.cabinList}>
                        <li>1 Queen size bed</li>
                        <li>1 Queen size inflatable mattress</li>
                    </ul>
                    <p> Pool is open seasonally: from May to September. </p>
                    <p>
                        The pool is a shared amenity with the host and another
                        guest cabin. We can coordinate private pool time for
                        your family by request.
                    </p>
                    <h4>POLICIES:</h4>
                    <ul className={style.cabinList}>
                        <li>
                            No smoking in the house or anywhere on the property.
                        </li>
                        <li>No pets.</li>
                        <li>
                            No parties or events without special permission and
                            additional fees.
                        </li>
                        <li>Max 4 guests in house</li>
                    </ul>
                    <h4>
                        Hosts live on site and are available for assistance
                        during your stay.
                    </h4>
                    <p>
                    Check out our links to <Link to={'https://www.airbnb.com/h/resortlivingpooltenniscourt'} target="_blank" style={{color: '#2196F3',fontWeight: 600,letterSpacing: '.5px'}} > Airbnb </Link> &  <Link to={'https://www.vrbo.com/2746514?unitId=3318387'} target="_blank" style={{color: '#2196F3',fontWeight: 600,letterSpacing: '.5px'}} > VRBO </Link> for availability

                    </p>
                </div>
                {/* <div className={style.bookingForm}>
                    <h2>INQUIRE ABOUT BOOKING THIS POOLSIDE CABANA!</h2>

                    <div className={style.formPage}>
                        <div className={style.dateField}>
                            <div className={style.formControl}>
                                <label>Date to Check-in *</label>
                                <input
                                    className={style.inputDate}
                                    type="date"
                                    placeholder="DD/MM/YYYY"
                                ></input>
                                <div
                                    id="bf2-2-error"
                                    className={style.errorTxt}
                                >
                                    <p>This field is required</p>
                                </div>
                            </div>
                            <div className={style.formControl}>
                                <label>Date to Check-out *</label>
                                <input
                                    className={style.inputDate}
                                    type="date"
                                    placeholder="DD/MM/YYYY"
                                ></input>
                                <div
                                    id="bf2-2-error"
                                    className={style.errorTxt}
                                >
                                    <p>This field is required</p>
                                </div>
                            </div>
                        </div>
                        <div className={style.inputField}>
                            <label>Name *</label>
                            <input
                                type="text"
                                className={style.inputForm}
                                placeholder="Your Name"
                            ></input>
                            <div id="bf2-2-error" className={style.errorTxt}>
                                <p>This field is required</p>
                            </div>
                        </div>
                        <div className={style.inputField}>
                            <label>Email *</label>
                            <input
                                type="text"
                                className={style.inputForm}
                                placeholder="Your Email"
                            ></input>
                            <div id="bf2-2-error" className={style.errorTxt}>
                                <p>This field is required</p>
                            </div>
                        </div>
                        <div className={style.inputField}>
                            <label>Phone Number</label>
                            <input
                                type="text"
                                className={style.inputForm}
                                placeholder="Your Phone Number"
                            ></input>
                            <div id="bf2-2-error" className={style.errorTxt}>
                                <p>This field is required</p>
                            </div>
                        </div>
                        <div className={style.inputField}>
                            <label>Number of Guests *</label>
                            <input
                                type="text"
                                className={style.inputForm}
                                placeholder="Number of Guests"
                            ></input>
                            <div id="bf2-2-error" className={style.errorTxt}>
                                <p>This field is required</p>
                            </div>
                        </div>
                        <div className={style.inputField}>
                            <label>Any Details or questions </label>
                            <textarea
                                type="text"
                                className={style.textareaForm}
                                placeholder="Type Your Question here"
                            ></textarea>
                            <div id="bf2-2-error" className={style.errorTxt}>
                                <p>This field is required</p>
                            </div>
                        </div>
                        <div className={style.buttonField}>
                            <MuiButton
                                CustomStyle={style.buttonSubmit}
                                LabelName={"Submit"}
                                iconStyle={{ display: "none" }}
                            />
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default PoolsideCabana;
