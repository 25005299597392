import React from "react";
import style from "./BannerSection.module.css";
import CrossImg from "../../assets/png/Cross-Bridge.png";
import MuiButton from "../../ui/button/MuiButton";
import { useNavigate } from "react-router-dom";

const BannerSection = () => {
    const navigate = useNavigate();
    const handleGuest = (e) => {
        e.preventDefault();
        navigate("/guest");
    };
    const handleContact = (e) => {
        e.preventDefault();
        navigate("/contactUs");
    };
    return (
        <div className={style.BannerSection}>
            <div className={style.innerBanner}></div>
            <div className={style.centerAlignment}>
                <div>
                    <img
                        src={CrossImg}
                        alt="Cross Bridge"
                        className={style.crossBridgeImg}
                    />
                    <h1 className={style.titleName}>
                        CROSSBRIDGE <br /> CREEK
                    </h1>
                    <p className={style.paraName}>
                        Event Center & Luxury Guest Cabins In the heart of
                        Edmond Oklahoma
                    </p>
                    <div className={style.buttonSection}>
                        <MuiButton
                            CustomStyle={style.bookButton}
                            LabelName={"Book Your event today"}
                            onClick={handleContact}
                        />
                        <MuiButton
                            CustomStyle={style.bookButton}
                            LabelName={"Guest Cabins"}
                            onClick={handleGuest}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BannerSection;
