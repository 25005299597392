import React, { useEffect } from "react";
import BannerBrown from "../../features/BannerBrown/BannerBrown";
import Header from "../../features/Header/Header";
import PrivateCabin from "../../features/PrivateCabin/PrivateCabin";
import PoolsideCabana from "../../features/PoolsideCabana/PoolsideCabana";
import WoodLandRoom from "../../features/WoodLandRoom/WoodLandRoom";
import Footer from "../../features/Footer/Footer";

const Guest = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);
    return (
        <>
            <Header />
            <BannerBrown Title={"GUEST CABINS"} />
            <PrivateCabin />
            <PoolsideCabana />
            <WoodLandRoom />
            <Footer />
        </>
    );
};

export default Guest;
