import React from "react";
import style from "./Footer.module.css";
import CrossImgBlack from "../../assets/png/Cross-Bridge-Black.png";

import FbImg from "../../assets/svg/fb.svg";
import Instra from "../../assets/svg/in.svg";
import MuiButton from "../../ui/button/MuiButton";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
const Footer = () => {
    const navigate = useNavigate();
    const handleHome = (e) => {
        e.preventDefault();
        navigate("/");
    };
    const handleGuest = (e) => {
        e.preventDefault();
        navigate("/guest");
    };
    const handleGallery = (e) => {
        e.preventDefault();
        navigate("/gallery");
    };
    const handleContact = (e) => {
        e.preventDefault();
        navigate("/contactUs");
    };
    return (
        <div className={style.footerContainer}>
            <div className={style.innerContainer}>
                <div className={style.leftFooter}>
                    <img
                        className={style.crossBridge}
                        alt="Cross Bridge"
                        src={CrossImgBlack}
                    />
                </div>
                <div className={style.rightFooter}>
                    <div className={style.footerContent}>
                        <h2>READY FOR YOUR EVENT ?</h2>
                        <p>We would love to have you here.</p>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <MuiButton
                                CustomStyle={style.contactButton}
                                LabelName={"contact us to book your dates"}
                                onClick={handleContact}
                            />
                        </div>
                        <div className={style.navFooter}>
                            <ul>
                                <li onClick={handleHome}>Home</li>
                                <li onClick={handleGuest}>Guest Cabins</li>
                                <li onClick={handleGallery}>Gallery</li>
                                <li onClick={handleContact}>Contact Us</li>
                            </ul>
                        </div>
                        <div className={style.socialIcon}>
                            <ul>
                                <li>
                                    <div className={style.fbColor}>
                                        <Link to='https://www.facebook.com/crossbridgecreek/' target="_blank" > 
                                            <img
                                                src={FbImg}
                                                alt="FbImg"
                                                className={style.svgIcons}
                                            />
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className={style.instraColor}>
                                        <Link to='https://www.instagram.com/crossbridge_creek/' target="_blank"> 
                                        <img
                                            src={Instra}
                                            alt="Instra"
                                            className={style.svgIcons}
                                        />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={style.copyRites}>
                <p>© CrossBridge Creek | Created by Cyean Digital</p>
            </div> */}
        </div>
    );
};

export default Footer;
