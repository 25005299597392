import React from "react";
import EastIcon from "@mui/icons-material/East";

const MuiButton = ({
    CustomStyle,
    style,
    LabelName,
    onClick,
    iconStyle
}) => {
    return (
        <>
            <button
                className={CustomStyle}
                onClick={onClick}
                style={{
                    outline: "none",
                    padding: "12px 25px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    cursor: 'pointer',
                }}
            >
                {LabelName}
                <span>
                    <EastIcon style={iconStyle}/>
                </span>
            </button>
        </>
    );
};

export default MuiButton;
