import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import style from "./ContactMap.module.css";

const ContactMap = () => {
    return (
        <div className={style.contactContainer}>
            <div className={style.InnerContainer}>
                <div className={style.ourContact}>
                    <h2>OUR CONTACT</h2>
                    <div className={style.dividedWhite}></div>
                    <div className={style.mapForm}>
                        <LocationOnIcon
                            alt="Location"
                            className={style.locationIcon}
                        />
                        <div className={style.contactDetails}>
                            <h4>My address</h4>
                            <p>
                                2601 W Covell Road, Edmond OK 73012, United
                                States.
                            </p>
                        </div>
                    </div>

                    <div className={style.mapForm}>
                        <CallIcon
                            className={style.locationIcon}
                            alt="CallIcon"
                        />
                        <div className={style.contactDetails}>
                            <h4>Call me</h4>
                            <p>405-265-9585</p>
                        </div>
                    </div>
                    <div className={style.mapForm}>
                        <MailIcon
                            className={style.locationIcon}
                            alt="MailIcon"
                        />
                        <div className={style.contactDetails}>
                            <h4>Mail me</h4>
                            <p>crossbridgecreek@protonmail.com</p>
                        </div>
                    </div>
                </div>
                <div className={style.viewMap}>
                    <div className={style.mapAlignment}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3371.604488759513!2d-97.52001778864725!3d35.682140529809736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87b1f5f8edad7f8b%3A0xb5421b87292054a1!2s2601%20W%20Covell%20Rd%2C%20Edmond%2C%20OK%2073012%2C%20USA!5e1!3m2!1sen!2sin!4v1696593843212!5m2!1sen!2sin"
                            width="600"
                            height="450"
                            title="map"
                            style={{ border: 0 }}
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactMap;
