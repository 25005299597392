import React, { useEffect } from 'react';
import Header from '../../features/Header/Header';
import BannerSection from '../../features/BannerSection/BannerSection';
import VenueSection from '../../features/VenueSection/VenueSection';
import Footer from '../../features/Footer/Footer';

const Home = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);
    return (
        <div>
            <Header />
            <BannerSection />
            <VenueSection />
            <Footer />
        </div>
    );
};

export default Home;